<script setup>
import { pastelTheme, darkTheme, Notivue, Notification } from 'notivue';

defineProps({
  item: {
    type: Object,
    default(rawProps) {
      return { title: 'Default', message: 'Default message' };
    },
  },
});

const { user } = storeToRefs(useBaseStore());

const theme = computed(() => {
  user.value.theme === 'dark' ? darkTheme : pastelTheme;
})
</script>

<template>
  <Notivue v-slot="item">
    <Notification
      :item="item"
      :theme="theme"
    />
  </Notivue>
</template>

<style lang="scss">
:root {
  --header-height: 64px;
  --nv-root-top: calc(var(--header-height) + 1rem);
}
</style>
